<template>
  <div class="c-input">
    <input ref="inputRef" type="text" class="px-2" :placeholder="placeholder" />
  </div>
</template>

<script>
import { useCurrencyInput } from 'vue-currency-input';

export default {
  name: 'CurrencyInput',
  props: {
    modelValue: Number | String,
    options: Object,
    placeholder: String,
  },
  setup(props) {
    const { inputRef, setValue } = useCurrencyInput(props.options);

    watch(
      () => props.modelValue,
      (value) => {
        setValue(value);
      }
    );

    return { inputRef };
  },
};
</script>

<style lang="sass" scoped></style>
