<template>
  <ion-modal
    class="modal-small"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="dismissModal"
  >
    <modals-layout :data="{ title: modalTitle }" @dismiss-modal="dismissModal">
      <div class="d-flex flex-column justify-content-center align-items-center h-100">
        <div class="message">Are you sure you want to remove this listing?</div>
        <div class="actions d-flex mt-3">
          <ion-button color="danger" @click="deleteListing"> Delete </ion-button>

          <ion-button color="medium" @click="dismissModal"> Cancel </ion-button>
        </div>
      </div>
    </modals-layout>
  </ion-modal>
</template>

<script lang="ts" setup>
defineProps({ modalTitle: { type: String }, isOpen: { type: Boolean } });

const emits = defineEmits(['dismissModal', 'deleteListing']);

const deleteListing = () => {
  emits('deleteListing');
  emits('dismissModal');
};

const dismissModal = () => emits('dismissModal');
</script>

<style lang="sass" scoped>
.modal-small
  --height: 200px
</style>
